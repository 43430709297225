import request from "@/utils/request";

// export var uploadDomain = "http://127.0.0.1:30020/api/v1/storage/upload";
export var uploadDomain = "https://api.um56.com/api/v1/storage/upload";

export var serviceUrl = "https://secure.livechatinc.com/licence/17470827/v2/open_chat.cgi?groups=0"

// const baseDomain = "ws://127.0.0.1:30020/ws"
const baseDomain = "wss://api.um56.com/ws"

export var baseUsdtId = 100
export var dataDomain = baseDomain + "/data";
export var depthDomain = baseDomain + "/depth";
export var kDataDomain = baseDomain + "/kdata";

export function getSystemParam(query) {
    return request({
        url: "/api/v1/system/system",
        method: "get",
        params: query,
    });
}

export function getItemList(query) {
    return request({
        url: "/api/v1/system/category",
        method: "get",
        params: query,
    });
}

export function getItemDetail(id) {
    return request({
        url: "/api/v1/system/category/" + id,
        method: "get",
    });
}

export function getLinkList(query) {
    return request({
        url: "/api/v1/system/link",
        method: "get",
        params: query,
    });
}

export function getArticleList(query) {
    return request({
        url: "/api/v1/system/article",
        method: "get",
        params: query,
    });
}

export function getArticleDetail(id) {
    return request({
        url: "/api/v1/system/article/" + id,
        method: "get",
    });
}
