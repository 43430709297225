export default {
    foot: {
        nav1: "Beranda",
        nav2: "Pasar",
        nav3: "Perdagangan",
        nav4: "Instant",
        nav5: "Dompet",
    },
    userLevel: {
        message: "Message",
        score: "Credit Score",
        up: "Up",
        down: "Down",
        inviteId: "Inviter",
        inviteIdMessage: "Please enter the inviter ID",
        level0: "Pengguna Biasa",
        level1: "Pengguna Perak",
        level2: "Pengguna Emas",
        level3: "Pengguna Platinum",
        level4: "Pengguna Berlian",
        level5: "Pengguna Raja",
        level6: "Pengguna Tertinggi",
    },
    currenctType: {
        title: "Silakan pilih mata uang",
        currenctType6: 'USDT-ERC20',
        currenctType7: 'USDT-TRC20',
        currenctType8: 'BTC',
        currenctType13: 'ETH',
        currenctType14: 'Dolar Amerika',
        currenctType15: 'Dolar Hong Kong',
        currenctType16: 'Dolar Taiwan',
        currenctType17: 'Mata Uang Kuda',
        currenctType18: 'Mata uang baru',
        currenctType19: 'Yen Jepang',
        currenctType20: 'Euro',
        currenctType21: 'Dolar Australia',
        currenctType22: 'Rupiah Indonesia',
        currenctType23: 'Poundsterling',
        currenctType24: 'Baht Thailand',
        currenctType25: 'Dolar Kanada',
        currenctType26: 'Rupiah Vietnam',
        currenctType27: 'Dolar Korea',
        currenctType28: 'Macao Dollar',
    },
    account: {
        login: "Masukkan",
        label_eMail: "Kotak surat",
        place_eMail: "Silakan masukkan alamat email Anda",
        tip_eMail: "Masukkan alamat email Anda dengan benar",
        label_password: "Kata sandi",
        place_password: "Masukkan kata sandi Anda",
        tip_password: "Masukkan kata sandi yang benar",
        no_account: "Belum ada akun.",
        register: "Pendaftaran",
        send: "Kirim Kode Verifikasi",
        label_code: "Kode Validasi",
        place_code: "Kode Validasi",
        tip_code: "Masukkan kode verifikasi kotak surat",
    },
    assets: {
        title: "Liabilitas",
        total_assets: "Total aset",
        recharge: "Mengisi ulang",
        withdraw: "Penarikan",
        available: "Kegunaan",
        freeze: "Beku",
        total: "Total",
        label_bank: "Jenis mata uang",
        place_bank: "Pilih mata uang",
        tip_bank: "Pilih mata uang",
        label_amount: "Jumlah",
        place_amount: "Silakan masukkan jumlahnya",
        tip_amount: "Harap masukkan jumlah yang benar",
        label_password: "Kata sandi",
        place_password: "Masukkan kata sandi Anda",
        tip_password: "Masukkan kata sandi yang benar",
        label_balance: "USDT yang tersedia",
        all: "Semua uang tunai",
        no_password: "Untuk keamanan dana Anda, silakan atur",
        withdraw_psd: "Kata Sandi Penarikan",
        label_wallet: "Dompet",
        place_wallet: "Silakan pilih dompet",
        tip_wallet: "Silakan pilih dompet",
    },
    currency: {
        position: "Kepemilikan",
        drawer_title: "Perdagangan Mata Uang",
        now: "Saat ini",
        open: "Buka sekaleng cacing",
        volume: "Omset",
        height: "Tertinggi",
        low: "Terendah",
        buy: "Beli lebih banyak",
        sell: "Jual pendek",
        name: "Nama ",
        direction: "Orientasi",
        crm_order: "Konfirmasi Pesanan",
        now_price: "Harga spot",
        type_time: "Waktu pengiriman (geser ke kiri untuk pengembalian yang lebih tinggi)",
        second: "Second",
        buy_amount: "Jumlah Pembelian",
        available: "Kegunaan",
        service_fee: "Biaya Penanganan",
        min_number: "Terkecil",
        errorTip1: "Jumlah yang dimasukkan salah",
        errorTip2: "Saldo yang tersedia tidak mencukupi",
        auto_jump: "Secara otomatis melompat ke pesanan setelah",
        keep: "Teruslah memesan.",
        revenue: "Pengembalian yang diharapkan",
    },
    home: {
        quick: "Siram Cepat",
        support: "Pembantu",
        nav1: "Berbagi ",
        nav2: "Dompet",
        nav3: "Informasi",
        nav4: "Tutorial",
        nav5: "Pertolongan pertama",
        nav6: "Menggali mineral",
        nav7: "Tentang Kami",
        nav8: "Aplikasi Koin Baru",
        increase: "Daftar Melonjak",
        volume: "Omset",
        table_name: "Nama",
        table_lastPrice: "Harga Terbaru",
        table_increase: "Kenaikan dan penurunan harga",
        table_volume: "Perputaran",
    },
    lockming: {
        profit: "Baru-baru ini (penghasilan harian)",
        cycle: "Siklus Keuangan",
        limit: "Batas Lump Sum",
        dividend_time: "Jadwal Pembayaran Dividen",
        every_day: "Hari ini",
        funds: "Dana yang disimpan dalam bentuk perwalian",
        back: "Pengembalian saat kedaluwarsa",
        ransom: "Penebusan awal",
        label_amount: "Jumlah Janji",
        place_amount: "Silakan masukkan...",
        available: "Kegunaan",
        expect_income: "Perkiraan Pendapatan",
        title: "Penambangan Posisi Terkunci",
        tip: "Pertambangan",
        label_today_income: "Estimasi Pendapatan Hari Ini",
        label_total_income: "Akumulasi keuntungan",
        label_order: "Pesanan untuk Perwalian",
        label_number: "Jumlah Lump Sum",
        daily_return: "Tingkat pengembalian harian",
        subscribe: "Berlangganan",
        position: "Kepemilikan",
        label_state: "Status",
        subscribe_time: "Periode Pendaftaran",
        expiration_time: "Waktu Kadaluarsa",
        income: "Lanjutkan",
        errorTip1: "Silakan masukkan jumlahnya",
        tip_tit: "Apakah Anda yakin ingin berhenti lebih awal?",
    },
    market: {
        spots: "Spot",
        contract: "Kontrak",
        currency_trans: "Perdagangan Mata Uang",
        increase: "Daftar Melonjak",
        volume: "Omset",
        label_name: "Nama",
        label_lastPrice: "Harga Terbaru",
        table_increase: "Kenaikan dan penurunan harga",
        table_volume: "Perputaran",
    },
    my: {
        details: "Detail",
        help: "Pusat Bantuan",
        information: "Informasi",
        language: "Pengalihan Bahasa",
        password: {
            account_title: "Ubah Kata Sandi",
            withdraw_title: "Ubah Kata Sandi",
            label_oldPsd: "Kata Sandi Lama",
            place_oldPsd: "Masukkan kata sandi lama Anda",
            label_newPsd: "Kode Baru",
            place_newPsd: "Masukkan kata sandi baru",
            label_crmPsd: "Konfirmasi Kata Sandi",
            place_crmPsd: "Masukkan kembali kata sandi baru",
            withdraw_tip: "Jika Anda belum mengatur kata sandi penarikan, silakan kosongkan.",
            password_tips: "Kata sandi harus berupa kombinasi 6 ~ 16 bit huruf + angka.",
        },
        address: {
            title: "Alamat penarikan dana",
            account_number: "Nomor Rekening",
            add_btn: "Tambahkan Alamat",
            label_type: "Pilih Jenis",
            place_type: "Silakan pilih jenis",
            label_number: "Nomor Rekening",
            place_number: "Silakan masukkan nomor rekening Anda",
            wallet_address: 'Alamat Dompet',
            bank_name: 'Nama Bank',
            bank_address: "Alamat Bank",
            name: "Nama",
            payee_address: 'Alamat penerima pembayaran',
            swift_code: "Swift/Swift Code/BIC",
            aba: "ABA",
            branch_number: "Nama Cabang",
            branch_code: "Kode Cabang",
            bank_code: "Kode Stroke Perak",
            phone: "Nomor ponsel",
            iban: "IBAN",
            sort_code: "Sort Code",
            place_inner: "Silakan masukkan konten",
            bank_user: "Cardholder",
            bank_branch: "Cabang",
            bank_isfc: "ISFC",
            bank_number: "Nomor kartu bank",
            place_bank_name: "Silakan masukkan nama bank",
            place_bank_user: "Silakan masukkan nama pemilik kartu",
            place_bank_branch: "Silakan masukkan nama cabang",
            place_bank_number: "Silakan masukkan nomor kartu bank Anda",
            place_bank_isfc: "Silakan masukkan ISFC",
        },
        history: {
            title: "Sejarah",
            recharge_record: "Catatan Isi Ulang",
            withdraw_record: "Catatan Penarikan Dana",
            withdraw: "Penarikan",
            number: "Kuantitas",
            currency: "Mata uang",
            state: "Status",
            state0: "Menunggu audit",
            state1: "Selesai",
            state2: "Ditolak",
        },
        identity: {
            title: "Sertifikat",
            label_country: "Pilihan Kewarganegaraan",
            place_country: "Pilih kewarganegaraan Anda",
            label_name: "Nama",
            place_name: "Silakan masukkan nama Anda",
            label_id: "Nomor Sertifikat",
            place_id: "Masukkan nomor KTP/Surat Izin Mengemudi/Paspor Anda.",
        },
        my: {
            list1: "Sertifikat",
            list2: "Manajemen Pesanan",
            list3: "Catatan Pendanaan",
            list4: "Alamat penarikan dana",
            list5: "Ganti Bahasa",
            list6: "Undang Teman",
            list7: "Pertolongan pertama",
            list8: "Tutorial",
            list9: "Ubah Kata Sandi",
            logout: "Keluar",
        },
        order: {
            title: "Kepemilikan",
            direction: "Orientasi",
            sell_price: "Tanyakan harga",
            number: "Kuantitas",
            type: "Waktu Pengiriman",
            buy_price: "Harga Penawaran",
            profit: "Keuntungan dan kerugian",
            sell_time: "Waktu Jual",
        },
        share: {
            title: "Undang Teman",
            save_ewm: "Simpan Kode QR",
            invite_link: "Tautan Undangan",
            copy_address: "Alamat Salinan",
            invite_code: "Kode Pendaftaran",
            copy_code: "Salin Konten",
            tip: "Daftarkan diri Anda melalui kode QR atau tautan di atas",
        },

    },
    transaction: {
        spots: "Spot",
        contract: "Kontrak",
        currency_trans: "Perdagangan Mata Uang",
        exchange: "Tembus",
        sustainable: "Abadi",
        warehouse: "Dermaga demi Dermaga",
        jump_lever: "Memanfaatkan",
        sell: "Jual",
        buy: "Dukungan",
        available: "Kegunaan",
        check_price: "Batas harga",
        market_price: "Nilai pasar",
        number: "Kuantitas",
        max: "Terbesar",
        bail: "Margin",
        buy_long: "Beli lebih banyak",
        sell_short: "Jual pendek",
        last_price: "Harga Terbaru",
        now_entrust: "Sebelumnya Ditugaskan",
        now_position: "Posisi saat ini",
        table_amount: "Jumlah Penarikan",
        is_reduce: "Kurangi posisi Anda saja",
        shi: "Menjadi",
        fou: "Tersumbat",
        position_number: "Posisi",
        is_profit: "Realisasi Laba",
        wei: "Tidak",
        open_price: "Harga Pembukaan",
        mark_price: "Harga yang Ditandai",
        flat_price: "Harga Flat yang Kuat",
        trim_lever: "Leverage ",
        flat_store: "Hirakura",
        trim_bail: "Penyesuaian Margin",
        reduce_bail: "Pengurangan margin",
        now_bail: "Margin Posisi Saat Ini",
        max_add: "Peningkatan maksimum",
        refer_flat: "Paritas Kuat Referensi yang Disesuaikan",
        expect_profit: "Proyeksi Laba dan Rugi"
    },


    common: {
        confirm: "Konfirmasi",
        all: "Penuh",
        close: "Pembatalan",
        copy_success: "Berhasil disalin.！",
        copy_error: "Gagal Menyalin！",
        place_input: "Silakan masukkan konten",
        more: "Lebih lanjut",
        main: "Peta Utama",
        sub: "Subplot",
        success: "Sukses！",
        cancel_tip: "Apakah Anda yakin ingin membatalkan?",
        notice: "Pemberitahuan",
        logout_tip: "Konfirmasi penarikan dana？",
        tip: "Menarik perhatian ke sth.",
        day: "Hari"
    },
    params: {
        transType0: "Spot",
        transType1: "Kontrak",
        directionType0: "Dukungan",
        directionType1: "Jual",
        lockStateType0: "Berlari",
        lockStateType1: "Ditutup",
        lockStateType2: "Dibatalkan",
    },
    city: {
        albania: "Albania",
        algeria: "Aljazair",
        argentina: "Argentina",
        armenia: "Armenia",
        australia: "Australia",
        pakistan: "Pakistan",
        austria: "Austria",
        bahrain: "Bahrain",
        belgium: "Belgia",
        bosnia_and_Herzegovina: "Bosnia dan Herzegovina",
        brazil: "Brazil",
        brunei: "Brunei",
        bulgaria: "Bulgaria",
        cambodia: "Kambodja",
        canada: "Kanada",
        cameroon: "Kamerun",
        chile: "Chile",
        colombia: "Kolombia",
        costa_Rica: "Costa Rica",
        croatia: "KroatiaName",
        cyprus: "Cyprus",
        czech_Republic: "Republik Ceko",
        denmark: "Denmark",
        dominican_Republic: "Republik DominikanName",
        egypt: "Mesir",
        estonia: "Estonia",
        ethiopia: "Ethiopia",
        finland: "Finland",
        france: "Perancis",
        georgia: "Georgia",
        germany: "Jerman",
        ghana: "Ghana",
        greece: "Yunani",
        guyana: "Guyana",
        honduras: "Honduras",
        hong_Kong_China: "Hong Kong, Tiongkok",
        hungary: "Hungary",
        iceland: "Islandia",
        ireland: "Irlandia",
        italy: "Italia",
        india: "India",
        indonesia: "Indonesia",
        israel: "Israel",
        iran: "Iran",
        iraq: "Irak",
        japan: "Jepang",
        kazakstan: "Republik Kazakh",
        kenya: "Kenya",
        korea: "Korea",
        kuwait: "Kuwait",
        kyrgyzstan: "Kirgistan",
        laos: "Laos",
        latvia: "Latvia",
        lithuania: "Lithuania",
        luxembourg: "Luxemburg",
        macao_China: "Macao China",
        macedonia: "Macedonia",
        malaysia: "Malaysia",
        malta: "Malta",
        mexico: "Meksiko",
        moldova: "Moldava",
        monaco: "Monaco",
        mongolia: "Mongolia",
        montenegro: "Republik Montenegro",
        morocco: "Maroko",
        myanmar: "Myanmar",
        netherlands: "Belanda",
        new_Zealand: "Seland Baru",
        nepal: "Nepal",
        nigeria: "Nigeria",
        norway: "Norwegia",
        oman: "Oman",
        palestine: "Palestina",
        panama: "Panama",
        paraguay: "Paraguay",
        peru: "Peru",
        philippines: "Filipina",
        poland: "Poland",
        portugal: "Portugal",
        puerto_Rico: "Puerto Rico",
        qatar: "Qatar",
        romania: "Rumania",
        russia: "Rusia",
        republic_of_Trinidad_and_Tobago: "Republik Trinidad dan Tobago",
        rwanda: "Ruanda",
        saudi_Arabia: "Arab Saudi",
        serbia: "Serbia",
        singapore: "Singapura",
        slovakia: "Slovakia",
        slovenia: "Slovenia",
        south_Africa: "Afrika Selatan",
        spain: "Spanyol",
        sri_Lanka: "Sri Lanka",
        sweden: "Swedia",
        switzerland: "瑞Swiss士",
        taiwan_China: "Taiwan China",
        tajikistan: "Republik Tajikistan",
        tanzania: "Tanzania",
        thailand: "Thailand",
        turkey: "T ürkiye",
        turkmenistan: "Republik Turkmenistan",
        ukraine: "Ukraina",
        united_Arab_Emirates: "Emirat Arab Serikat",
        united_Kingdom: "Inggris",
        united_States: "Amerika Serikat",
        uzbekistan: "Uzbekistan",
        venezuela: "Venezuela",
        vietnam: "Vietnam",
        afghanistan: "Afghanistan",
        angola: "Angola",
        azerbaijan: "Azerbaijan",
        bangladesh: "Bengal",
        belarus: "Belarus",
        belize: "Belize",
        benin: "Benin",
        bhutan: "Bhutan",
        bolivia: "Bolivia",
        botswana: "Botswana",
        british_Virgin_Islands: "Kepulauan Perawan Inggris",
        burkina_Faso: "Burkina faso",
        burundi: "Burundi",
        cape_Verde: "Cape Verde",
        cayman_Islands: "Kepulauan Cayman",
        central_African_Republic: "Republik Afrika Tengah",
        chad: "Chad",
        comoros: "Komor",
        the_Republic_of_the_Congo: "Congo (Brazzaville)",
        democratic_Republic_of_the_Congo: "Republik Demokratik Kongo",
        djibouti: "Djibouti",
        ecuador: "Ecuador",
        el_Salvador: "El Salvador",
        equatorial_Guinea: "Guinea Ekvatorial",
        eritrea: "Eritrea",
        fiji: "Fiji",
        gabon: "Gabon",
        gambia: "Gambia",
        greenland: "Greenland",
        guatemala: "Guatemala",
        guinea: "Guinea",
        haiti: "Haiti",
        isle_of_Man: "Pulau Man",
        cote_d_Ivoire: "Cote d'Ivoire",
        jamaica: "Jamaica",
        jordan: "Jordan",
        lebanon: "Lebanon",
        lesotho: "Lesotho",
        liberia: "Liberia",
        libya: "Libya",
        madagascar: "Madagaskar",
        malawi: "Malawi",
        maldives: "Maldives",
        mali: "Mali",
        mauritania: "Mauritania",
        mauritius: "Mauritius",
        mozambique: "Mozambique",
        namibia: "Namibia",
        nicaragua: "Nicaragua",
        republic_of_Niger: "Niger",
        north_Korea: "Korea Utara",
        reunion: "Pulau Reunion",
        san_Marino: "San Marino",
        senegal: "Senegal",
        sierra_Leone: "Sierra Leone",
        somalia: "Somalia",
        sudan: "Sudan",
        suriname: "Surinam",
        eswatini: "Eswatini",
        syria: "Syria",
        togo: "Togo",
        tonga: "Tonga",
        tunisia: "Tunisia",
        united_States_Virgin_Islands: "Pulau Perawan AS",
        uganda: "Uganda",
        uruguay: "Uruguay",
        vatican: "Vatikan",
        yemen: "Yemen",
        yugoslavia: "Yugoslavia",
        zambia: "Zambia",
        zimbabwe: "Zimbabwe",
        china: "China",
    }
};
