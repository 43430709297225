export default {
    foot: {
        nav1: "ホーム",
        nav2: "相場",
        nav3: "取り引き",
        nav4: "通貨",
        nav5: "資産",
    },
    userLevel: {
        message: "Message",
        score: "Credit Score",
        up: "Up",
        down: "Down",
        inviteId: "Inviter",
        inviteIdMessage: "Please enter the inviter ID",
        level0: "一般ユーザー",
        level1: "シルバーユーザー",
        level2: "ゴールドユーザー",
        level3: "プラチナユーザー",
        level4: "ダイヤモンドユーザー",
        level5: "王者ユーザー",
        level6: "エクストリーム・ユーザー",
    },
    currenctType: {
        title: "通貨を選択してください",
        currenctType6: 'USDT-ERC20',
        currenctType7: 'USDT-TRC20',
        currenctType8: 'BTC',
        currenctType13: 'ETH',
        currenctType14: 'ドル',
        currenctType15: '香港ドル',
        currenctType16: '台湾ドル',
        currenctType17: 'コイン枚',
        currenctType18: '新貨',
        currenctType19: '円',
        currenctType20: 'ユーロ',
        currenctType21: '豪ドル',
        currenctType22: 'インドネシア盾',
        currenctType23: 'ポンド',
        currenctType24: 'バーツ',
        currenctType25: 'かさん',
        currenctType26: 'ベトナムドン',
        currenctType27: '韓国ドル',
        currenctType28: 'マカオドル',
    },
    account: {
        login: "ログイン",
        label_eMail: "メールボックス",
        place_eMail: "メールアドレスを入力してください",
        tip_eMail: "メールアドレスを正しく入力してください",
        label_password: "パスワード",
        place_password: "パスワードを入力してください",
        tip_password: "正しいパスワードを入力してください",
        no_account: "まだアカウントを持っていませんか？",
        register: "登録",
        send: "認証コードを送信" ,
        label_code: "Eメール認証コード",
        place_code: "メール認証コード",
        tip_code: "メール認証コードを入力してください",
    },
    assets: {
        title: "資産",
        total_assets: "総資産",
        recharge: "再チャージ",
        withdraw: "引き出し",
        available: "利用可能",
        freeze: "凍結",
        total: "合計",
        label_bank: "通貨＃ツウカ＃",
        place_bank: "通貨を選択してください",
        tip_bank: "通貨を選択してください",
        label_amount: "金額",
        place_amount: "金額を入力してください",
        tip_amount: "正しい金額を入力してください",
        label_password: "パスワード",
        place_password: "パスワードを入力してください",
        tip_password: "正しいパスワードを入力してください",
        label_balance: "利用可能なUSDT",
        all: "すべての引き出し",
        no_password: "資金の安全のために設定してください",
        withdraw_psd: "引き出しパスワード",
        label_wallet: "財布",
        place_wallet: "財布を選択してください",
        tip_wallet: "財布を選択してください",
    },
    currency: {
        position: "ポジション",
        drawer_title: "currency_trade",
        now: "現在",
        open: "オープン",
        volume: "出来高",
        height: "最高値",
        low: "最安値",
        buy: "買いロング",
        sell: "空売り",
        name: "名前",
        direction: "方向",
        crm_order: "注文の確認",
        now_price: "現在の価格",
        type_time: "配信時間(左スライドで高リターン)",
        second: "秒",
        buy_amount: "購入金額",
        available: "利用可能",
        service_fee: "手数料",
        min_number: "最低",
        errorTip1: "金額入力に誤りがある",
        errorTip2: "使用可能残高が不足しています",
        auto_jump: "後自動受注ジャンプ",
        keep: "注文を続行",
        revenue: "予想収益",
    },
    home: {
        quick: "クイックコインフラッシュ",
        support: "サポート",
        nav1: "シェア",
        nav2: "ウォレット",
        nav3: "情報",
        nav4: "チュートリアル",
        nav5: "カスタマーサービス",
        nav6: "マイニング",
        nav7: "会社概要",
        nav8: "新規コイン登録",
        increase: "アップリスト",
        volume: "数量",
        table_name: "名前",
        table_lastPrice: "最新価格",
        table_increase: "アップ/ダウン",
        table_volume: "ボリューム",
    },
    lockming: {
        profit: "直近（日次収益）",
        cycle: "金融サイクル",
        limit: "シングルリミット",
        dividend_time: "配当支払時期",
        every_day: "毎日",
        funds: "エスクロー資金",
        back: "満期時のリターン",
        ransom: "早期償還",
        label_amount: "誓約金額",
        place_amount: "入力してください,,",
        available: "利用可能",
        expect_income: "期待される収入",
        title: "ロックアップマイニング",
        tip: "収入を得るための採掘",
        label_today_income: "今日の予想収入",
        label_total_income: "累積収入",
        label_order: "エスクロー中の注文",
        label_number: "単一金額",
        daily_return: "日次リターン",
        subscribe: "購読",
        position: "ポジション",
        label_state: "ステータス",
        subscribe_time: "購読時間",
        expiration_time: "有効期限",
        income: "収入",
        errorTip1: "金額を入力してください",
        tip_tit: "本当に早めに退出しますか",
    },
    market: {
        spots: "在庫あり",
        contract: "契約",
        currency_trans: "货币交易",
        increase: "上昇幅ラ",
        volume: "成約する",
        label_name: "の名前",
        label_lastPrice: "最新価格",
        table_increase: "上昇幅",
        table_volume: "ターンオーバー",
    },
    my: {
        details: "詳細",
        help: "ヘルプセンター",
        information: "情報",
        language: "言語スイッチ",
        password: {
            account_title: "パスワードの変更",
            withdraw_title: "パスワードの変更",
            label_oldPsd: "古いパスワード",
            place_oldPsd: "古いパスワードを入力してください",
            label_newPsd: "新しいパスワード",
            place_newPsd: "新しいパスワードを入力してください",
            label_crmPsd: "パスワードの確認",
            place_crmPsd: "もう一度新しいパスワードを入力してください",
            withdraw_tip: "引き出しパスワードを設定していない場合は、空白のままにしてください",
            password_tips: "パスワードは6～16文字+数字の組み合わせでなければなりません",
        },
        address: {
            title: "ウォレットのアドレス",
            account_number: "口座番号",
            add_btn: "アドレスを追加",
            label_type: "タイプを選択してください",
            place_type: "タイプを選択してください",
            label_number: "口座番号",
            place_number: "口座番号を入力してください",
            wallet_address: 'ウォレットアドレス',
            bank_name: '銀行名',
            bank_address: "銀行住所",
            name: "名前",
            payee_address: '受取人住所',
            swift_code: "Swift/Swift Code/BIC",
            aba: "ABA",
            branch_number: "支店名",
            branch_code: "支店コード",
            bank_code: "銀行コード",
            phone: "携帯番号",
            iban: "IBAN",
            sort_code: "Sort Code",
            place_inner: "内容を入力してください",
            bank_user: "カード所持者",
            bank_branch: "支店",
            bank_isfc: "ISFC",
            bank_number: "銀行カード番号",
            place_bank_name: "銀行名を入力してください",
            place_bank_user: "カード所有者名を入力してください",
            place_bank_branch: "支店名を入力してください",
            place_bank_number: "銀行カード番号を入力してください",
            place_bank_isfc: "ISFCを入力してください",
        },
        history: {
            title: "履歴",
            recharge_record: "リチャージ記録",
            withdraw_record: "引き出し記録",
            withdraw: "引き出し記録",
            number: "数値＃ス",
            currency: "通貨",
            state: "ステータス",
            state0: "保留",
            state1: "完了",
            state2: "却下",
        },
        identity: {
            title: "認証",
            label_country: "国籍を選択してください",
            place_country: "国籍を選択してください",
            label_name: "名前",
            place_name: "名前を入力してください",
            label_id: "ID番号",
            place_id: "ID/運転免許証/パスポート番号を入力してください",
        },
        my: {
            list1: "認証",
            list2: "注文管理",
            list3: "お金の記録",
            list4: "ウォレットアドレス",
            list5: "言語の切り替え",
            list6:"友達を招待する",
            list7:"カスタマーサービス",
            list8:"チュートリアル",
            list9: "パスワードの変更",
            logout: "ログアウト",
        },
        order: {
            title: "ポジション",
            direction: "方向",
            sell_price: "売りレート",
            number: "数量",
            type: "納期",
            buy_price: "買値",
            profit: "損益",
            sell_time: "sell_time",
        },
        share: {
            title: "友達を招待",
            save_ewm: "QRコードを保存",
            invite_link: "招待リンク",
            copy_address: "アドレスをコピー",
            invite_code: "登録コード",
            copy_code: "内容をコピー",
            tip: "上記のQRコードまたはリンクから登録",
        },

    },
    transaction: {
        spots: "在庫あり",
        contract: "契約",
        currency_trans: "通貨取引",
        exchange: "交換",
        sustainable: "永続的",
        warehouse: "倉庫別",
        jump_lever: "ジャンプレバー",
        sell: "売る",
        buy: "購入",
        available: "利用可能",
        check_price: "価格制限",
        market_price: "市場価格",
        number: "番号",
        max: "最大",
        bail: "マージン",
        buy_long: "買いロング",
        sell_short: "空売り",
        last_price: "直近の価格",
        now_entrust: "current_entrust",
        now_position: "現在のポジション",
        table_amount: "引き出し額",
        is_reduce: "縮小ポジションのみ",
        shi:"はい",
        fou: "いいえ",
        position_number: "保有ポジション数",
        is_profit: "実現利益",
        wei: "まだ",
        open_price: "建値",
        mark_price: "マーク価格",
        flat_price: "strong_level_price",
        trim_lever: "レバレッジの調整",
        flat_store: "クローズポジション",
        trim_bail: "マージンを調整",
        reduce_bail: "マージンを減らす",
        now_bail:"現在のポジションのマージン",
        max_add: "最大増加幅",
        refer_flat: "調整後の基準強平価",
        expect_profit: "予想損益",
    },
    common: {
        confirm: "確認",
        all: "すべて",
        close: "キャンセル",
        copy_success: "コピー成功！",
        copy_error: "コピー失敗！" ,
        place_input: "内容を入力してください" ,
        more: "詳細",
        main: "マスタ図",
        sub: "ふくず",
        success: "成功！",
        cancel_tip: "本当にキャンセルしますか？" ,
        notice: "に知らせる",
        logout_tip: "終了を確認しますか？",
        tip: "ヒント",
        day: "日"
    },
    params: {
        transType0: "現物",
        transType1: "契約",
        directionType0: "購入する",
        directionType1: "売り出す",
        lockStateType0: "実行中",
        lockStateType1: "終了",
        lockStateType2: "キャンセル済み",
    },
    city: {
		albania: "アルバニア",
		algeria: "アルジェリア",
		argentina: "アルゼンチン",
		armenia: "アルメニア",
		australia: "オーストラリア",
		pakistan: "パキスタン",
		austria: "オーストリア",
		bahrain: "オーストリア",
		belgium: "ベルギー",
		bosnia_and_Herzegovina: "ボスニア・ヘルツェゴビナ",
		brazil: "ブラジル",
		brunei: "ブルネイ",
		bulgaria: "ブルガリア",
		cambodia: "カンボジア",
		canada: "カナダ",
		cameroon: "カメルーン",
		chile: "チリ",
		colombia: "コロンビア",
		costa_Rica: "コスタリカ",
		croatia: "クロアチア",
		cyprus: "キプロス",
		czech_Republic: "チェコ共和国",
		denmark: "デンマーク",
		dominican_Republic: "ドミニカ共和国",
		egypt: "エジプト",
		estonia: "エストニア",
		ethiopia: "エチオピア",
		finland: "フィンランド",
		france: "フランス",
		georgia: "グルジア",
		germany: "ドイツ",
		ghana: "ガーナ",
		greece: "ギリシャ",
		guyana: "ガイアナ",
		honduras: "ホンジュラス",
		hong_Kong_China: "中国香港",
		hungary: "ハンガリー",
		iceland: "アイスランド",
		ireland: "アイルランド",
		italy: "イタリア",
		india: "インド",
		indonesia: "インドネシア",
		israel: "イスラエル",
		iran: "イラン",
		iraq: "イラク",
		japan: "日本",
		kazakstan: "カザフスタン",
		kenya: "ケニア",
		korea: "韓国",
		kuwait: "クウェート",
		kyrgyzstan: "キルギス",
		laos: "ラオス",
		latvia: "ラトビア",
		lithuania: "リトアニア",
		luxembourg: "ルクセンブルク",
		macao_China: "中国マカオ",
		macedonia: "マケドニア",
		malaysia: "マレーシア",
		malta: "マルタ",
		mexico: "メキシコ",
		moldova: "モルドバ",
		monaco: "モナコ",
		mongolia: "モンゴル",
		montenegro: "モンテネグロ共和国",
		morocco: "モロッコ",
		myanmar: "ミャンマー",
		netherlands: "オランダ",
		new_Zealand: "ニュージーランド",
		nepal: "ネパール",
		nigeria: "ナイジェリア",
		norway: "ノルウェー",
		oman: "オマーン",
		palestine: "パレスチナ",
		panama: "パナマ",
		paraguay: "パラグアイ",
		peru: "ペルー",
		philippines: "フィリピン",
		poland: "ポーランド",
		portugal: "ポルトガル",
		puerto_Rico: "プエルトリコ",
		qatar: "カタール",
		romania: "ルーマニア",
		russia: "ロシア",
		republic_of_Trinidad_and_Tobago: "トリニダード・トバゴ共和国",
		rwanda: "ルワンダ",
		saudi_Arabia: "サウジアラビア",
		serbia: "セルビア",
		singapore: "シンガポール",
		slovakia: "スロバキア",
		slovenia: "スロベニア",
		south_Africa: "南アフリカ共和国",
		spain: "スペイン",
		sri_Lanka: "スリランカ",
		sweden: "スウェーデン",
		switzerland: "スイス",
		taiwan_China: "中国台湾",
		tajikistan: "タジキスタン",
		tanzania: "タンザニア",
		thailand: "タイ",
		turkey: "トルコ",
		turkmenistan: "トルクメニスタン",
		ukraine: "ウクライナ",
		united_Arab_Emirates: "アラブ首長国連邦",
		united_Kingdom: "英国",
		united_States: "アメリカ",
		uzbekistan: "ウズベキスタン",
		venezuela: "ベネズエラ",
		vietnam: "ベトナム",
		afghanistan: "アフガニスタン",
		angola: "アンゴラ",
		azerbaijan: "アゼルバイジャン",
		bangladesh: "バングラデシュ",
		belarus: "ベラルーシ",
		belize: "ベリーズ",
		benin: "ベニン",
		bhutan: "ブータン",
		bolivia: "ボリビア",
		botswana: "ボツワナ",
		british_Virgin_Islands: "イギリス領ヴァージン諸島",
		burkina_Faso: "ブルキナファソ",
		burundi: "ブルンジ",
		cape_Verde: "カーボベルデ角",
		cayman_Islands: "ケイマン諸島",
		central_African_Republic: "中央アフリカ共和国",
		chad: "チャド",
		comoros: "コモロ",
		the_Republic_of_the_Congo: "コンゴ（布）",
		democratic_Republic_of_the_Congo: "コンゴ（金）",
		djibouti: "ジブチ",
		ecuador: "エクアドル",
		el_Salvador: "エルサルバドル",
		equatorial_Guinea: "赤道ギニア",
		eritrea: "エリトリア",
		fiji: "フィジー",
		gabon: "ガボン",
		gambia: "ガンビア",
		greenland: "グリーンランド",
		guatemala: "グアテマラ",
		guinea: "ギニア",
		haiti: "ハイチ",
		isle_of_Man: "マーン島",
		cote_d_Ivoire: "コートジボワール",
		jamaica: "ジャマイカ",
		jordan: "ヨルダン",
		lebanon: "レバノン",
		lesotho: "レソト",
		liberia: "リベリア",
		libya: "リビア",
		madagascar: "マダガスカル",
		malawi: "マラウイ",
		maldives: "モルディブ",
		mali: "マリ",
		mauritania: "モーリタニア",
		mauritius: "モーリシャス",
		mozambique: "モザンビーク",
		namibia: "ナミビア",
		nicaragua: "ニカラグア",
		republic_of_Niger: "ニジェール",
		north_Korea: "朝鲜",
		reunion: "レユニオン",
		san_Marino: "サンマリノ",
		senegal: "セネガル",
		sierra_Leone: "シエラレオネ",
		somalia: "ソマリア",
		sudan: "スーダン",
		suriname: "スリナム",
		eswatini: "スワジランド",
		syria: "シリア",
		togo: "トーゴ",
		tonga: "トンガ",
		tunisia: "チュニジア",
		united_States_Virgin_Islands: "アメリカ領ヴァージン諸島",
		uganda: "ウガンダ",
		uruguay: "ウルグアイ",
		vatican: "バチカン",
		yemen: "イエメン",
		yugoslavia: "ユーゴスラビア",
		zambia: "ザンビア",
		zimbabwe: "ジンバブエ",
		china: "中国",
	}
};
