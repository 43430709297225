export default {
    foot: {
        nav1: "Home",
        nav2: "mercato",
        nav3: "transazione",
        nav4: "valuta",
        nav5: "asset",
    },
    userLevel: {
        message: "Message",
        score: "Credit Score",
        up: "Up",
        down: "Down",
        inviteId: "Inviter",
        inviteIdMessage: "Please enter the inviter ID",
        level0: "Utenti ordinari",
        level1: "Utenti d'argento",
        level2: "Utenti d'oro",
        level3: "Utenti Platinum",
        level4: "Utenti di diamanti",
        level5: "Utente Il Re",
        level6: "Utente finale",
    },
    currenctType: {
        title: "Seleziona una valuta",
        currenctType6: 'USDT-ERC20',
        currenctType7: 'USDT-TRC20',
        currenctType8: 'BTC',
        currenctType13: 'ETH',
        currenctType14: 'dollaro',
        currenctType15: 'Dollari di Hong Kong',
        currenctType16: 'Valuta di Taiwan',
        currenctType17: 'mir',
        currenctType18: 'Nuova valuta',
        currenctType19: 'Yen giapponese',
        currenctType20: 'Euro',
        currenctType21: 'AUD',
        currenctType22: 'Rupiah indonesiano',
        currenctType23: 'sterlina',
        currenctType24: 'Thailandese baht',
        currenctType25: 'Cad',
        currenctType26: 'đŭng vietnamita',
        currenctType27: 'Won coreano',
        currenctType28: 'Macao Pataca',
    },
    account: {
        login: "Accesso",
        label_eMail: "Casella di posta elettronica",
        place_eMail: "Inserire l'indirizzo e-mail",
        tip_eMail: "Inserisci correttamente il tuo indirizzo e-mail",
        label_password: "Password",
        place_password: "Inserire la password",
        tip_password: "Inserire la password corretta",
        no_account: "Non avete ancora un account?",
        register: "Registrati",
        send: "Invia codice di verifica",
        label_code: "Codice di verifica e-mail",
        place_code: "Codice di verifica e-mail",
        tip_code: "Inserire il codice di verifica dell'e-mail",
    },
    assets: {
        title: "Attività",
        total_assets: "Totale attività",
        recharge: "ricarica",
        withdraw: "prelevare",
        available: "disponibile",
        freeze: "congelato",
        total: "Totale",
        label_bank: "valuta",
        place_bank: "Seleziona una valuta",
        tip_bank: "Seleziona una valuta",
        label_amount: "Importo",
        place_amount: "Inserire l'importo",
        tip_amount: "Inserire l'importo corretto",
        label_password: "Password",
        place_password: "Inserire la password",
        tip_password: "Inserire la password corretta",
        label_balance: "USDT disponibili",
        all: "Tutti i prelievi",
        no_password: "Si prega di impostare questa opzione per la sicurezza dei fondi",
        withdraw_psd: "Password di prelievo",
        label_wallet: "portafoglio",
        place_wallet: "Seleziona un portafoglio",
        tip_wallet: "Seleziona un portafoglio",
    },
    currency: {
        position: "Posizione",
        drawer_title: "currency_trade",
        now: "corrente",
        open: "Aperto",
        volume: "Volume",
        height: "massimo",
        low: "minimo",
        buy: "Acquista lungo",
        sell: "vendita breve",
        name: "nome",
        direction: "direzione",
        crm_order: "Conferma ordine",
        now_price: "prezzo_attuale",
        type_time: "Tempo di consegna (slide a sinistra per rendimenti più elevati)",
        second: "secondi",
        buy_amount: "importo di acquisto",
        available: "disponibile",
        service_fee: "commissione",
        min_number: "a partire da ",
        errorTip1: "Importo errato",
        errorTip2: "Saldo disponibile insufficiente",
        auto_jump: "Passa automaticamente all'ordine dopo",
        keep: "Continuare a effettuare ordini",
        revenue: "Rendimenti attesi",
    },
    home: {
        quick: "Sciacquone rapido",
        support: "Supporto",
        nav1: "Condividi",
        nav2: "Portafoglio",
        nav3: "Info",
        nav4: "Tutorial",
        nav5: "Servizio clienti",
        nav6: "Miniere",
        nav7: "Chi siamo",
        nav8: "Abbonamenti a nuove monete",
        increase: "Up List",
        volume: "Volume",
        table_name: "Nome",
        table_lastPrice: "Ultimo prezzo",
        table_increase: "Up/Down",
        table_volume: "Volume",
    },
    lockming: {
        profit: "Guadagni recenti (giornalieri)",
        cycle: "Ciclo finanziario",
        limit: "Limite singolo",
        dividend_time: "Tempo di pagamento dei dividendi",
        every_day: "Giornaliero",
        funds: "Fondi in deposito",
        back: "Ritorno alla scadenza",
        ransom: "Riscatto anticipato",
        label_amount: "Importo del pegno",
        place_amount: "Inserire,,,",
        available: "disponibile",
        expect_income: "Reddito previsto",
        title: "Mining bloccato",
        tip: "Mining for income",
        label_today_income: "Guadagni stimati oggi",
        label_total_income: "Guadagno cumulativo",
        label_order: "Ordini in deposito",
        label_number: "Importo singolo",
        daily_return: "Rendimento giornaliero",
        subscribe: "sottoscrizione",
        position: "Posizione",
        label_state: "Stato",
        subscribe_time: "Tempo di sottoscrizione",
        expiration_time: "Tempo di scadenza",
        income: "reddito",
        errorTip1: "Inserisci l'importo",
        tip_tit: "Sei sicuro di voler uscire presto",
    },
    market: {
        spots: "现货",
        contract: "合约",
        currency_trans: "货币交易",
        increase: "涨幅榜",
        volume: "成交量",
        label_name: "名称",
        label_lastPrice: "最新价",
        table_increase: "涨跌幅",
        table_volume: "成交额",
    },
    my: {
        details: "Dettagli",
        help: "Centro assistenza",
        information: "Informazioni",
        language: "Cambio lingua",
        password: {
            account_title: "Cambia password",
            withdraw_title: "Cambia password",
            label_oldPsd: "Vecchia password",
            place_oldPsd: "Inserire la vecchia password",
            label_newPsd: "Nuova password",
            place_newPsd: "Inserire una nuova password",
            label_crmPsd: "Confermare la password",
            place_crmPsd: "Inserire nuovamente una nuova password",
            withdraw_tip: "Se non avete impostato una password di prelievo, lasciatela vuota",
            password_tips: "La password deve essere una combinazione di 6-16 lettere e numeri",
        },
        address: {
            title: "Indirizzo del portafoglio",
            account_number: "Numero di conto",
            add_btn: "Aggiungi indirizzo",
            label_type: "Selezionare il tipo",
            place_type: "Selezionare il tipo",
            label_number: "Numero di conto",
            place_number: "Inserire il numero di conto",
            wallet_address: 'Indirizzo del portafoglio',
            bank_name: 'Nome della banca',
            bank_address: "Indirizzo bancario",
            name: "Nome",
            payee_address: 'Indirizzo del destinatario',
            swift_code: "Swift/Swift Code/BIC",
            aba: "ABA",
            branch_number: "Nome del ramo",
            branch_code: "Codice branch",
            bank_code: "Codice bancario",
            phone: "Numero di cellulare",
            iban: "IBAN",
            sort_code: "Sort Code",
            place_inner: "Inserisci il contenuto",
            bank_user: "Titolare della carta",
            bank_branch: "Branch",
            bank_isfc: "ISFC",
            bank_number: "Numero della carta di credito",
            place_bank_name: "Inserisci il nome della banca",
            place_bank_user: "Inserisci il nome del titolare della carta",
            place_bank_branch: "Inserisci il nome della filiale",
            place_bank_number: "Inserisci il numero della tua carta di credito",
            place_bank_isfc: "Inserisci ISFC",
        },
        history: {
            title: "Storia",
            recharge_record: "Record di ricarica",
            withdraw_record: "Registrazione di prelievo",
            withdraw: "Prelievi",
            number: "numero",
            currency: "valuta",
            state: "Stato",
            state0: "In attesa",
            state1: "Completato",
            state2: "Rifiutato",
        },
        identity: {
            title: "Autenticazione",
            label_country: "Selezionare una nazionalità",
            place_country: "Selezionare una nazionalità",
            label_name: "Nome",
            place_name: "Inserire il proprio nome",
            label_id: "Numero ID",
            place_id: "Inserire il numero di carta d'identità/patente/passaporto",
        },
        my: {
            list1: "Autenticazione",
            list2: "Gestione ordini",
            list3: "Record di denaro",
            list4: "Indirizzo portafoglio",
            list5: "Cambia lingua",
            list6: "Invita amici",
            list7: "Servizio clienti",
            list8: "Tutorial",
            list9: "Cambia password",
            logout: "Logout",
        },
        order: {
            title: "Posizione",
            direction: "direzione",
            sell_price: "tasso di vendita",
            number: "quantità",
            type: "Tempo di consegna",
            buy_price: "Prezzo_offerta",
            profit: "profitto/perdita",
            sell_time: "sell_time",
        },
        share: {
            title: "Invita gli amici",
            save_ewm: "Salva codice QR",
            invite_link: "Collegamento per invitare",
            copy_address: "Copia indirizzo",
            invite_code: "Codice di registrazione",
            copy_code: "Copia del contenuto",
            tip: "Registrazione tramite il codice QR o il link di cui sopra",
        },
    },
    transazione: {
        spots: "spot",
        contract: "contratto",
        currency_trans: "currency_transactions",
        exchange: "flash",
        sustainable: "perpetuo",
        warehouse: "posizione per posizione",
        jump_lever: "leva di salto",
        sell: "vendere",
        buy: "acquistare",
        available: "disponibile",
        check_price: "prezzo_limite",
        market_price: "prezzo_mercato",
        number: "quantity",
        max: "max",
        bail: "margine",
        buy_long: "Compra lungo",
        sell_short: "Vendita breve",
        last_price: "Ultimo prezzo",
        now_entrust: "current_entrust",
        now_position: "Posizione attuale",
        table_amount: "Importo del prelievo",
        is_reduce: "Solo per ridurre la posizione",
        shi: "Sì",
        fou: "No",
        position_number: "Numero di posizioni detenute",
        is_profit: "Profitto realizzato",
        wei: "Non ancora",
        open_price: "Prezzo aperto",
        mark_price: "prezzo di riferimento",
        flat_price: "prezzo_di_livello_forte",
        trim_lever: "Regola la leva",
        flat_store: "Chiudi posizione",
        trim_bail: "Regola il margine",
        reduce_bail: "Riduci margine",
        now_bail: "Margine di posizione attuale",
        max_add: "Aumento massimo",
        refer_flat: "Prezzo fisso forte di riferimento rettificato",
        expect_profit: "Profitto/perdita previsti",
    },
    common: {
        confirm: "Conferma",
        tutti: "Tutti",
        close: "Annulla",
        copy_success: "Copia riuscita!",
        copy_error: "Copia non riuscita!",
        place_input: "Inserire il contenuto",
        more: "di più",
        main: "Immagine principale",
        sub: "Sottoplot",
        success: "Successo!",
        cancel_tip: "Sei sicuro di voler cancellare?" ,
        notice: "Notifica",
        logout_tip: "Sei sicuro di uscire?",
        tip: "prompt",
        day: "giorno"
    },
    params: {
        transType0: "Spot",
        transType1: "contratto",
        directionType0: "Compra",
        directionType1: "Vendi",
        lockStateType0: "In esecuzione",
        lockStateType1: "Terminato",
        lockStateType2: "Annullato",
    },
    city: {
		albania: "Albania",
		algeria: "Algeria",
		argentina: "Argentina",
		armenia: "Armenia",
		australia: "Australia",
		pakistan: "Pakistan",
		austria: "Austria",
		bahrain: "Bahrein",
		belgium: "Belgio",
		bosnia_and_Herzegovina: "Bosnia_Erzegovina",
		brazil: "Brasile",
		brunei: "Brunei",
		bulgaria: "Bulgaria",
		cambodia: "Cambogia",
		canada: "Canada",
		cameroon: "Camerun",
		chile: "Cile",
		colombia: "Colombia",
		costa_Rica: "Costa Rica",
		croatia: "Croazia",
		cyprus: "Cipro",
		czech_Republic: "Repubblica Ceca",
		denmark: "Danimarca",
		dominican_Republic: "Repubblica Dominicana",
		egypt: "Egitto",
		estonia: "Estonia",
		ethiopia: "Etiopia",
		finland: "Finlandia",
		france: "Francia",
		georgia: "Georgia",
		germany: "Germania",
		ghana: "Ghana",
		greece: "Grecia",
		guyana: "Guyana",
		honduras: "Honduras",
		hong_Kong_China: "Hong Kong, Cina",
		hungary: "Ungheria",
		iceland: "Islanda",
		ireland: "Irlanda",
		italy: "Italia",
		india: "India",
		indonesia: "Indonesia",
		israel: "Israele",
		iran: "Iran",
		iraq: "Iraq",
		japan: "Giappone",
		kazakstan: "Kazakistan",
		kenya: "Kenya",
		korea: "Corea",
		kuwait: "Kuwait",
		kyrgyzstan: "Kirghizistan",
		laos: "Laos",
		latvia: "Lettonia",
		lithuania: "Lituania",
		luxembourg: "Lussemburgo",
		macao_China: "Macao, Cina",
		macedonia: "Macedonia",
		malaysia: "Malaysia",
		malta: "Malta",
		mexico: "Messico",
		moldova: "Moldavia",
		monaco: "Monaco",
		mongolia: "Mongolia",
		montenegro: "Repubblica di Montenegro",
		morocco: "Marocco",
		myanmar: "Myanmar",
		netherlands: "Paesi Bassi",
		new_Zealand: "Nuova Zelanda",
		nepal: "Nepal",
		nigeria: "Nigeria",
		norway: "Norvegia",
		oman: "Oman",
		palestine: "Palestina",
		panama: "Panama",
		paraguay: "Paraguay",
		peru: "Perù",
		philippines: "Filippine",
		poland: "Polonia",
		portugal: "Portogallo",
		puerto_Rico: "Puerto Rico",
		qatar: "Qatar",
		romania: "Romania",
		russia: "Russia",
		republic_of_Trinidad_and_Tobago: "Repubblica di Trinidad e Tobago",
		rwanda: "Ruanda",
		saudi_Arabia: "Arabia Saudita",
		serbia: "Serbia",
		singapore: "Singapore",
		slovakia: "Slovacchia",
		slovenia: "Slovenia",
		south_Africa: "Sudafrica",
		spain: "Spagna",
		sri_Lanka: "Sri Lanka",
		sweden: "Svezia",
		switzerland: "Svizzera",
		taiwan_China: "Taiwan, Cina",
		tajikistan: "Tagikistan",
		tanzania: "Tanzania",
		thailand: "Thailandia",
		turkey: "Türkiye",
		turkmenistan: "Turkmenistan",
		ukraine: "Ucraina",
		united_Arab_Emirates: "Emirati Arabi Uniti",
		united_Kingdom: "Gran Bretagna",
		united_States: "Stati Uniti",
		uzbekistan: "Uzbekistan",
		venezuela: "Venezuela",
		vietnam: "Vietnam",
		afghanistan: "Afghanistan",
		angola: "Angola",
		azerbaijan: "Azerbaigian",
		bangladesh: "Bangladesh",
		belarus: "Bielorussia",
		belize: "Belize",
		benin: "Benin",
		bhutan: "Bhutan",
		bolivia: "bolivia",
		botswana: "Botswana",
		british_Virgin_Islands: "Isole Vergini Britanniche",
		burkina_Faso: "burkina faso",
		burundi: "Burundi",
		cape_Verde: "Capo Verde",
		cayman_Islands: "Isole Cayman",
		central_African_Republic: "Repubblica Centrafricana",
		chad: "Ciad",
		comoros: "Comore",
		the_Republic_of_the_Congo: "Congo (Brazzaville)",
		democratic_Republic_of_the_Congo: "Congo (Kinshasa)",
		djibouti: "Gibuti",
		ecuador: "Ecuador",
		el_Salvador: "El Salvador",
		equatorial_Guinea: "Guinea Equatoriale",
		eritrea: "Eritrea",
		fiji: "Figi",
		gabon: "Gabon",
		gambia: "Gambia",
		greenland: "Groenlandia",
		guatemala: "Guatemala",
		guinea: "Guinea",
		haiti: "Haiti",
		isle_of_Man: "Isola di Man",
		cote_d_Ivoire: "Costa d'Avorio",
		jamaica: "Giamaica",
		jordan: "Giamaica",
		lebanon: "Libano",
		lesotho: "Lesotho",
		liberia: "Liberia",
		libya: "Libia",
		madagascar: "Madagascar",
		malawi: "Malawi",
		maldives: "Maldive",
		mali: "Mali",
		mauritania: "Mauritania",
		mauritius: "Mauritius",
		mozambique: "Mozambico",
		namibia: "Namibia",
		nicaragua: "Nicaragua",
		republic_of_Niger: "Niger",
		north_Korea: "Corea del Nord",
		reunion: "Réunion",
		san_Marino: "San Marino",
		senegal: "Senegal",
		sierra_Leone: "sierra leone",
		somalia: "Somalia",
		sudan: "Sudan",
		suriname: "Suriname",
		eswatini: "Eswatini",
		syria: "Siria",
		togo: "Togo",
		tonga: "Tonga",
		tunisia: "Tunisia",
		united_States_Virgin_Islands: "Isole Vergini Americane",
		uganda: "Uganda",
		uruguay: "Uruguay",
		vatican: "Vaticano",
		yemen: "Yemen",
		yugoslavia: "Jugoslavia",
		zambia: "Zambia",
		zimbabwe: "zimbabwe",
		china: "Cina",
	}

};
