export default {
    foot: {
        nav1: "主頁",
        nav2: "行情",
        nav3: "交易",
        nav4: "貨幣",
        nav5: "資產",
    },
    userLevel: {
        message: "信息",
        score: "信用分",
        up: "Up",
        down: "Down",
        inviteId: "邀请人",
        inviteIdMessage: "请输入邀请人ID",
        level0: "普通用戶",
        level1: "白銀用戶",
        level2: "黃金用戶",
        level3: "白金用戶",
        level4: "鑽石用戶",
        level5: "王者用戶",
        level6: "至尊用戶",
    },
    currenctType: {
        title: "請選擇貨幣",
        currenctType6: 'USDT-ERC20',
        currenctType7: 'USDT-TRC20',
        currenctType8: 'BTC',
        currenctType13: 'ETH',
        currenctType14: '美元',
        currenctType15: '港幣',
        currenctType16: '台幣',
        currenctType17: '馬幣',
        currenctType18: '新幣',
        currenctType19: '日元',
        currenctType20: '歐元',
        currenctType21: '澳元',
        currenctType22: '印尼盾',
        currenctType23: '英鎊',
        currenctType24: '泰銖',
        currenctType25: '加元',
        currenctType26: '越南盾',
        currenctType27: '韓幣',
        currenctType28: '澳門幣',
    },
    account: {
        login: "登入",
        label_eMail: "郵箱",
        place_eMail: "請輸入您的電子郵箱",
        tip_eMail: "請正確輸入您的電子郵箱",
        label_password: "密碼",
        place_password: "請輸入密碼",
        tip_password: "請輸入正確的密碼",
        no_account: "還沒有帳號？",
        register: "注册",
        send: "發送驗證碼",
        label_code: "郵箱驗證碼",
        place_code: "郵箱驗證碼",
        tip_code: "請輸入郵箱驗證碼",
    },
    assets: {
        title: "資產",
        total_assets: "總資產",
        recharge: "充值",
        withdraw: "提現",
        available: "可用",
        freeze: "凍結",
        total: "總計",
        label_bank: "幣種",
        place_bank: "請選擇幣種",
        tip_bank: "請選擇幣種",
        label_amount: "金額",
        place_amount: "請輸入金額",
        tip_amount: "請正確輸入金額",
        label_password: "密碼",
        place_password: "請輸入密碼",
        tip_password: "請輸入正確的密碼",
        label_balance: "可用USDT",
        all: "全部提現",
        no_password: "為了您的資金安全，請設定",
        withdraw_psd: "提現密碼",
        label_wallet: "錢包",
        place_wallet: "請選擇錢包",
        tip_wallet: "請選擇錢包",
    },
    currency: {
        position: "持倉",
        drawer_title: "貨幣交易",
        now: "當前",
        open: "開盤",
        volume: "成交量",
        height: "最高",
        low: "最低",
        buy: "買多",
        sell: "賣空",
        name: "名字",
        direction: "方向",
        crm_order: "確認訂單",
        now_price: "現價",
        type_time: "交割時間（左滑收益更高）",
        second: "秒",
        buy_amount: "買入金額",
        available: "可用",
        service_fee: "手續費",
        min_number: "最少",
        errorTip1: "金額輸入有誤",
        errorTip2: "可用餘額不足",
        auto_jump: "後自動跳轉訂單",
        keep: "繼續下單",
        revenue: "預期收益",
    },
    home: {
        quick: "快速沖幣",
        support: "支持",
        nav1: "分享",
        nav2: "錢包",
        nav3: "資訊",
        nav4: "教程",
        nav5: "客服",
        nav6: "挖礦",
        nav7: "關於我們",
        nav8: "新幣申購",
        increase: "漲幅榜",
        volume: "成交量",
        table_name: "名稱",
        table_lastPrice: "最新價",
        table_increase: "漲跌幅",
        table_volume: "成交額",
    },
    lockming: {
        profit: "近日(日收益)",
        cycle: "理財週期",
        limit: "單筆限額",
        dividend_time: "派息時間",
        every_day: "每日",
        funds: "託管資金",
        back: "到期返回",
        ransom: "提前贖回",
        label_amount: "質押金額",
        place_amount: "請輸入...",
        available: "可用",
        expect_income: "預計收益",
        title: "鎖倉挖礦",
        tip: "挖礦賺不停",
        label_today_income: "預計今日收益",
        label_total_income: "累計收益",
        label_order: "託管的訂單",
        label_number: "單筆數額",
        daily_return: "日收益率",
        subscribe: "申購",
        position: "持倉",
        label_state: "狀態",
        subscribe_time: "申購時間",
        expiration_time: "到期時間",
        income: "收益",
        errorTip1: "請輸入金額",
        tip_tit: "確定要提前退出嗎",
    },
    market: {
        spots: "現貨",
        contract: "合約",
        currency_trans: "貨幣交易",
        increase: "漲幅榜",
        volume: "成交量",
        label_name: "名稱",
        label_lastPrice: "最新價",
        table_increase: "漲跌幅",
        table_volume: "成交額",
    },
    my: {
        details: "詳情",
        help: "幫助中心",
        information: "資訊",
        language: "語言切換",
        password: {
            account_title: "修改密碼",
            withdraw_title: "修改密碼",
            label_oldPsd: "舊密碼",
            place_oldPsd: "請輸入舊密碼",
            label_newPsd: "新密碼",
            place_newPsd: "請輸入新密碼",
            label_crmPsd: "確認密碼",
            place_crmPsd: "請再次輸入新密碼",
            withdraw_tip: "如未設定過提現密碼，請留空",
            password_tips: "密碼必須為6 ~ 16比特字母+數位的組合",
        },
        address: {
            title: "取款地址",
            account_number: "帳戶號碼",
            add_btn: "添加地址",
            label_type: "選擇類型",
            place_type: "請選擇類型",
            label_number: "帳戶號碼",
            place_number: "請輸入帳戶號碼",
            wallet_address: '錢包地址',
            bank_name: '銀行名字',
            bank_address: "銀行地址",
            name: "姓名",
            payee_address: '收款人地址',
            swift_code: "Swift/Swift Code/BIC",
            aba: "ABA",
            branch_number: "分行名字",
            branch_code: "分行程式碼",
            bank_code: "銀行程式碼",
            phone: "手機號",
            iban: "IBAN",
            sort_code: "Sort Code",
            place_inner: "請輸入內容",
            bank_user: "持卡人",
            bank_branch: "分行",
            bank_isfc: "ISFC",
            bank_number: "银行卡号",
        },
        history: {
            title: "歷史記錄",
            recharge_record: "充值記錄",
            withdraw_record: "提現記錄",
            withdraw: "提現",
            number: "數量",
            currency: "貨幣",
            state: "狀態",
            state0: "待稽核",
            state1: "已完成",
            state2: "已拒絕",
        },
        identity: {
            title: "認證",
            label_country: "選擇國籍",
            place_country: "請選擇國籍",
            label_name: "姓名",
            place_name: "請輸入您的姓名",
            label_id: "證件號",
            place_id: "請輸入您的身份證/駕駛證/護照號",
        },
        my: {
            list1: "認證",
            list2: "訂單管理",
            list3: "資金記錄",
            list4: "取款地址",
            list5: "切換語言",
            list6: "邀請好友",
            list7: "客服",
            list8: "教程",
            list9: "修改密碼",
            logout: "退出登录",
        },
        order: {
            title: "持倉",
            direction: "方向",
            sell_price: "卖出價",
            number: "數量",
            type: "交割時間",
            buy_price: "買入價",
            profit: "盈虧",
            sell_time: "賣出時間",
        },
        share: {
            title: "邀請好友",
            save_ewm: "保存二維碼",
            invite_link: "邀請連結",
            copy_address: "複製地址",
            invite_code: "註冊碼",
            copy_code: "複製內容",
            tip: "通過以上二維碼或者連結進行注册",
        },

    },
    transaction: {
        spots: "現貨",
        contract: "合約",
        currency_trans: "貨幣交易",
        exchange: "閃兌",
        sustainable: "永續",
        warehouse: "逐倉",
        jump_lever: "跳轉杠杆",
        sell: "賣出",
        buy: "買入",
        available: "可用",
        check_price: "限價",
        market_price: "市價",
        number: "數量",
        max: "最大",
        bail: "保證金",
        buy_long: "買多",
        sell_short: "賣空",
        last_price: "最新價",
        now_entrust: "當前委託",
        now_position: "當前持倉",
        table_amount: "提現金額",
        is_reduce: "只減倉",
        shi: "是",
        fou: "否",
        position_number: "持倉數量",
        is_profit: "實現盈利",
        wei: "未",
        open_price: "開倉價格",
        mark_price: "標記價格",
        flat_price: "强平價格",
        trim_lever: "調整杠杆",
        flat_store: "平倉",
        trim_bail: "調整保證金",
        reduce_bail: "减少保證金",
        now_bail: "當前倉位保證金",
        max_add: "最多可增加",
        refer_flat: "調整後參攷强平價",
        expect_profit: "預計盈虧"
    },


    common: {
        confirm: "確認",
        all: "全部",
        close: "取消",
        copy_success: "複製成功！",
        copy_error: "複製失敗！",
        place_input: "請輸入內容",
        more: "更多",
        main: "主圖",
        sub: "副圖",
        success: "成功！",
        cancel_tip: "你确定要取消吗?",
        notice: "通知",
        logout_tip: "確認退出？",
        tip: "提示",
        day: "天"
    },
    params: {
        transType0: "現貨",
        transType1: "合約",
        directionType0: "買入",
        directionType1: "賣出",
        lockStateType0: "運行中",
        lockStateType1: "已結束",
        lockStateType2: "已取消",
    },
    city: {
        albania: "阿爾巴尼亞",
        algeria: "阿爾及利亞",
        argentina: "阿根廷",
        armenia: "亞美尼亞",
        australia: "澳大利亞",
        pakistan: "巴基斯坦",
        austria: "奧地利",
        bahrain: "巴林",
        belgium: "比利時",
        bosnia_and_Herzegovina: "波士尼亞和黑塞哥維那",
        brazil: "巴西",
        brunei: "汶萊",
        bulgaria: "保加利亞",
        cambodia: "柬埔寨",
        canada: "加拿大",
        cameroon: "喀麥隆",
        chile: "智利",
        colombia: "哥倫比亞",
        costa_Rica: "哥斯达黎加",
        croatia: "克羅地亞",
        cyprus: "塞浦路斯",
        czech_Republic: "捷克共和國",
        denmark: "丹麥",
        dominican_Republic: "多米尼加共和国",
        egypt: "埃及",
        estonia: "愛沙尼亞",
        ethiopia: "埃塞俄比亚",
        finland: "芬蘭",
        france: "法國",
        georgia: "格魯吉亞",
        germany: "德國",
        ghana: "加納",
        greece: "希臘",
        guyana: "蓋亞那",
        honduras: "洪都拉斯",
        hong_Kong_China: "中國香港",
        hungary: "匈牙利",
        iceland: "冰島",
        ireland: "愛爾蘭",
        italy: "義大利",
        india: "印度",
        indonesia: "印度尼西亞",
        israel: "以色列",
        iran: "伊朗",
        iraq: "伊拉克",
        japan: "日本",
        kazakstan: "哈薩克共和國",
        kenya: "肯尼亚",
        korea: "韓國",
        kuwait: "科威特",
        kyrgyzstan: "吉爾吉斯斯坦",
        laos: "老撾",
        latvia: "拉脫維亞",
        lithuania: "立陶宛",
        luxembourg: "盧森堡",
        macao_China: "中國澳門",
        macedonia: "馬其頓",
        malaysia: "馬來西亞",
        malta: "馬爾他",
        mexico: "墨西哥",
        moldova: "摩爾達瓦",
        monaco: "摩納哥",
        mongolia: "蒙古",
        montenegro: "黑山共和國",
        morocco: "摩洛哥",
        myanmar: "緬甸",
        netherlands: "荷蘭",
        new_Zealand: "新西蘭",
        nepal: "尼泊爾",
        nigeria: "尼日利亞",
        norway: "挪威",
        oman: "阿曼",
        palestine: "巴勒斯坦",
        panama: "巴拿馬",
        paraguay: "巴拉圭",
        peru: "秘魯",
        philippines: "菲律賓",
        poland: "波蘭",
        portugal: "葡萄牙",
        puerto_Rico: "波多黎各",
        qatar: "卡塔爾",
        romania: "羅馬尼亞",
        russia: "俄羅斯",
        republic_of_Trinidad_and_Tobago: "特立尼达和多巴哥共和国",
        rwanda: "卢旺达",
        saudi_Arabia: "沙烏地阿拉伯",
        serbia: "塞爾維亞",
        singapore: "新加坡",
        slovakia: "斯洛伐克",
        slovenia: "斯洛文尼亞",
        south_Africa: "南非",
        spain: "西班牙",
        sri_Lanka: "斯里蘭卡",
        sweden: "瑞典",
        switzerland: "瑞士",
        taiwan_China: "中國臺灣",
        tajikistan: "塔吉克共和國",
        tanzania: "坦尚尼亞",
        thailand: "泰國",
        turkey: "土耳其",
        turkmenistan: "土庫曼共和國",
        ukraine: "烏克蘭",
        united_Arab_Emirates: "阿拉伯聯合大公國",
        united_Kingdom: "英國",
        united_States: "美國",
        uzbekistan: "烏茲別克",
        venezuela: "委內瑞拉",
        vietnam: "越南",
        afghanistan: "阿富汗",
        angola: "安哥拉",
        azerbaijan: "阿塞拜疆",
        bangladesh: "孟加拉",
        belarus: "白俄羅斯",
        belize: "貝裡斯",
        benin: "貝南",
        bhutan: "不丹",
        bolivia: "玻利維亞",
        botswana: "博茨瓦纳",
        british_Virgin_Islands: "英屬維京群島",
        burkina_Faso: "伯基納法索",
        burundi: "布隆迪",
        cape_Verde: "佛得角",
        cayman_Islands: "開曼群島",
        central_African_Republic: "中非共和國",
        chad: "乍得",
        comoros: "科摩羅",
        the_Republic_of_the_Congo: "剛果（布）",
        democratic_Republic_of_the_Congo: "剛果（金）",
        djibouti: "吉佈提",
        ecuador: "厄瓜多尔",
        el_Salvador: "薩爾瓦多",
        equatorial_Guinea: "赤道幾內亞",
        eritrea: "厄立特里亚",
        fiji: "斐濟",
        gabon: "加蓬",
        gambia: "岡比亞",
        greenland: "格陵蘭",
        guatemala: "危地马拉",
        guinea: "幾內亞",
        haiti: "海地",
        isle_of_Man: "馬恩岛",
        cote_d_Ivoire: "科特迪瓦",
        jamaica: "牙買加",
        jordan: "約旦",
        lebanon: "黎巴嫩",
        lesotho: "萊索托",
        liberia: "利比里亚",
        libya: "利比亞",
        madagascar: "馬达加斯加",
        malawi: "馬拉维",
        maldives: "馬尔代夫",
        mali: "馬里",
        mauritania: "毛里塔尼亞",
        mauritius: "毛里求斯",
        mozambique: "莫桑比克",
        namibia: "納米比亞",
        nicaragua: "尼加拉瓜",
        republic_of_Niger: "尼日尔",
        north_Korea: "朝鮮",
        reunion: "留尼汪",
        san_Marino: "聖馬力諾",
        senegal: "塞內加爾",
        sierra_Leone: "塞拉利昂",
        somalia: "索馬里",
        sudan: "蘇丹",
        suriname: "蘇里南",
        eswatini: "斯威士兰",
        syria: "敘利亞",
        togo: "多哥",
        tonga: "汤加",
        tunisia: "突尼斯",
        united_States_Virgin_Islands: "美屬維爾京群島",
        uganda: "烏干達",
        uruguay: "烏拉圭",
        vatican: "梵蒂岡",
        yemen: "也门",
        yugoslavia: "南斯拉夫",
        zambia: "赞比亚",
        zimbabwe: "津巴布韦",
        china: "中國",
    }
};
